/* https://bootstrapious.com/p/bootstrap-sidebar */

body {
  background: #fafafa;
}

.App {
  display: flex;
  align-items: stretch;
}

#sidebar {
  min-width: 250px;
  max-width: 250px;
  min-height: 100vh;

  background: #7386d5;
  color: #fff;
  transition: all 0.3s;
}

#sidebar.active {
  margin-left: -250px;
}

#sidebar .sidebar-header {
  padding: 20px;
  background: #6d7fcc;
}

.main {
  position: relative;
  min-height: 100%;
  /*margin-bottom: 60px;*/
}

footer.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 !important;
  /* Set the fixed height of the footer here */
  height: 60px;
  line-height: 60px; /* Vertically center the text there */
}

.datamaps-subunit {
  cursor: pointer;
}
