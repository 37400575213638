#country-select {
  width: 340px;
}

#status-select {
  width: 150px;
}

#pages-select {
  width: 300px;
}

#platform-select {
  width: 340px;
}

#impression-condition-select {
  width: 150px;
}

.search-form label {
  margin-right: 10px;
  margin-left: 10px;
}

.btn-export {
  margin-left: 10px;
}

.word-cloud {
  /* we use negative margins to hide whitespace */
  z-index: -100;
}

.word-cloud svg {
  margin-top: -50px;
}

.table-pagination {
  display: flex;
}

.table-pagination div:nth-child(2) {
  margin-left: 5px;
  padding-top: 6px;
  width: 250px;
}

.column-action {
  cursor: pointer;
}

.column-action.inactive {
  color: lightgray;
}

.filter-popover .popover {
  min-height: 150px;
  min-width: 360px;
}

.coordination {
  .badge:not(:first-child) {
    margin-left: 5px;
  }
}
