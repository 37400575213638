.detail-table {
  font-size: 0.8em;
  padding: 0;

  &.table {
    display: inline-block;
    border-spacing: 0;
    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .tbody {
      .tr:hover {
        background: #f9f9f9;
      }
    }

    .th:hover {
      /* The resizer styles! */
      .resizer {
        display: inline-block;
        background: #9d7bd8;
        width: 8px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 0;
        &.isResizing {
          background: #5a4385;
        }
      }
    }

    .th,
    .td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #9c9c9c;
      border-right: 1px solid #efefef;
      /* In this example we use an absolutely position resizer, so this is required. */
      position: relative;
      &:last-child {
        border-right: 0;
      }
    }

    .td {
      max-height: 125px;
      overflow-y: auto;
    }
  }
}
